import { NgModule } from '@angular/core';
import { PreguntasMlModalComponent } from "./preguntas-ml-modal.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PreguntasMlListaComponent} from "../../../theme/pages/default/preguntas-ml/preguntas-ml-lista/preguntas-ml-lista.component";
import {DefaultComponent} from "../../../theme/pages/default/default.component";
import {CustomPipesModule} from "../../pipes/custom-pipes.module";
import {LayoutModule} from "../../../theme/layouts/layout.module";
const routes: Routes = [
    {
        "path": "",
        "component": DefaultComponent,
        "children": [
            {
                "path": "",
                "component": PreguntasMlModalComponent
            }
        ]
    }
];
@NgModule({
    imports: [FormsModule, CommonModule, NgbModule, RouterModule.forRoot(routes), CustomPipesModule, LayoutModule
    ], exports: [
        RouterModule, PreguntasMlModalComponent
    ], declarations: [
        PreguntasMlModalComponent,
    ]
})
export class PreguntasMlModalModule {


}