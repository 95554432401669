import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class ComparadorService extends ApiService {
  entityType = EntityType.COMPARADOR;
  url = 'api/marketplace/skumasters/productos';

  calcularPrecios(resource: any) {
    resource = this.serialize(resource);
    return this.http.put(`api/marketplace/skumasters/calcular-precios-stock`, resource);
  }

  listFast(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}-fast`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }
  getPaging(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}-fast-count`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }
}
