import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import { ErrorsError4Component } from './theme/pages/self-layout-blank/snippets/pages/errors/errors-error-4/errors-error-4.component';

const routes: Routes = [
  { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: '403', loadChildren: './shared/components/error403/error403.module#Error403Module' },
  { path: '404', loadChildren: './shared/components/error404/error404.module#Error404Module' },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
