import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'encode_url' })
export class EncodeUrlPipe implements PipeTransform {
    transform(url: string): string {
        while (url.includes('+')) {
            url = url.replace('+', '%2B');
        }
        return url;
    }
}