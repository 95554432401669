export enum EntityType {
  AMAZON = 'amazon',
  AMAZON_CATEGORIA = 'amazon-categoria',
  ARANCEL = 'arancel',
  ATRIBUTO = 'atributo',
  ATRIBUTO_SKUMASTER = 'atributo-skumaster',
  BESTBUY = 'bestbuy',
  BESTBUY_CATEGORIA = 'bestbuy-categoria',
  BODEGA = 'bodega',
  CAJA = 'caja',
  CALCULADORA = 'calculo-importacion',
  CALCULO_IMPORTACION = 'calculo-importacion', 
  CALCULO_PLATAFORMA = 'calculo-plataforma',
  CATEGORIA = 'categoria',
  CIUDAD = 'ciudad',
  CLIENTE = 'cliente',
  COMPARADOR = 'comparador',
  COMPRA = 'compra',
  DIMENSION_CAJA = 'dimension-caja',
  EMPRESA_ENVIO = 'empresa-envio',
  ENVIO = 'envio',
  ERROR_LOG = 'error-log',
  ESTADISTICAS = 'estadisticas',
  ITEM = 'item',
  LOG_PUBLICACION = 'log-publicacion',
  LOGISTICA = 'logistica',
  MAIL = 'mail',
  MEDIA = 'media',
  MERCADO_LIBRE = 'mercado-libre',
  MONEDA = 'moneda',
  NOTA = 'nota',
  NOTIFICACION = 'notificacion',
  PAIS = 'pais',
  PASARELA = 'pasarela',
  PLANTILLA = 'plantilla',
  PLATAFORMA = 'plataforma',
  PREGUNTA_ML = 'pregunta-ml',
  PRECIO_VENTA = 'precio-venta',
  PRODUCTO = 'producto',
  ARTICULO = 'articulo',
  PRODUCTO_COMPRA = 'producto-compra',
  PRODUCTO_VENTA = 'producto-venta',
  PROVEEDOR = 'proveedor',
  PROVINCIA = 'provincia',
  PUBLICACION_ML = 'publicacion-ml',
  REGLA_PUBLICACION = 'regla-publicacion',
  ROLE = 'role',
  TARIFARIO = 'tarifario',
  SHOPIFY = 'shopify',
  SITIO = 'sitio',
  SKUMASTER = 'skumaster',
  STOCK_LOCAL = 'stock-local',
  TRACKING = 'tracking',
  USER = 'usuario',
  VENTA = 'venta',
  WOOCOMMERCE = 'woo',
  ZONA = 'zona'
}
