import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediasService } from '../../services/api/medias.service';
import { Helpers } from '../../../helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PreguntasMlService } from '../../services/api/preguntas-ml.service';
import { MercadoLibreService } from '../../services/api/mercado-libre.service';
import { SkumastersService } from '../../services/api/skumasters.service';

@Component({
  selector: 'app-preguntas-ml-modal',
  templateUrl: './preguntas-ml-modal.component.html'
})
export class PreguntasMlModalComponent implements OnInit {
  @Input() id: any;
  item: any;
  pregunta: string;
  respuesta: string;
  indImage = 0;
  modalOpened = false;
  skumaster: any;
  atributos: any[] = [];
  variaciones: any[];
  combinaciones: any[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private preguntasService: PreguntasMlService,
    private mlService: MercadoLibreService,
    private skumasterService: SkumastersService
  ) {}

  ngOnInit() {

    this.getPregunta();
  }

  getPregunta() {
    this.preguntasService.getById(this.id).subscribe(pregunta => {
      this.getItem(pregunta.data);
    });
  }

  getItem(data) {
    this.indImage = 0;
    this.pregunta = data.text;
    /*this.mlService.getProducto(data.item_id).subscribe(item => {
            this.item = item.data;
            this.item.seller_custom_field = this.item.seller_custom_field || this.item.variations[0].seller_custom_field;
            this.getSkumaster(this.item.seller_custom_field);
            this.getAtributos(this.item.seller_custom_field);
            this.getVariaciones(this.item.seller_custom_field);
            this.getCombinaciones(this.item.seller_custom_field);
            this.changeImage();
            this.modalOpened = true;
        });*/
  }

  changeImage() {
    setTimeout(() => {
      this.indImage = (this.indImage + 1) % this.item.pictures.length;
      if (this.modalOpened) this.changeImage();
    }, 2000);
  }

  getSkumaster(skumaster) {
    this.skumaster = null;
    this.skumasterService.getByIdAndLanguage(skumaster, 'es').subscribe(skumaster => {
      this.skumaster = skumaster.data;

    });
  }

  getAtributos(skumaster) {
    this.atributos = [];
    this.skumasterService.getAtributos(skumaster).subscribe(atributos => {
      this.atributos = atributos.data;

    });
  }

  getVariaciones(skumaster) {
    this.variaciones = null;
    this.skumasterService.getVariaciones(skumaster).subscribe(variaciones => {
      this.variaciones = variaciones.data;

    });
  }

  getCombinaciones(skumaster) {
    this.combinaciones = [];
    this.skumasterService.getCombinaciones(skumaster).subscribe(combinaciones => {
      this.combinaciones = combinaciones.data;

    });
  }
}
