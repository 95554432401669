import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth/auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  inflightAuthRequest = null;

  constructor(private injector: Injector, private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | any> {
    const auth = this.injector.get(AuthService);
    const authReq = req.clone({
      headers: req.headers.append('Authorization', 'Bearer ' + this.auth.getAccessToken())
    });
    return next.handle(authReq).pipe(
      catchError(error => {
        // checks if a url is to an admin api or not
        if (error.status === 401) {
          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = auth.refreshToken();

            if (!this.inflightAuthRequest) {
              // remove existing tokens
              localStorage.clear();
              auth.logout();
              return throwError(error);
            }
          }
          return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              // clone the original request
              const authReqRepeat = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + newToken)
              });

              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }
}
