import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserCredentials } from "@app/shared/model/user-credentials.model";
import { Helpers } from "../helpers";
import { UsuariosService } from "../shared/services/api/usuarios.service";
import { AuthService } from "../shared/services/auth/auth.service";
import { MessageService } from "../shared/services/message/message.service";
import { StorageService } from "../shared/services/storage/storage.service";
import { ScriptLoaderService } from "../_services/script-loader.service";
import { LoginCustom } from "./_helpers/login-custom";

@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
  templateUrl: "./login/login.component.html",
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  credentials: UserCredentials = {};
  loading = false;
  returnUrl: string;

  @ViewChild("alertSignin", { read: ViewContainerRef })
  alertSignin: ViewContainerRef;
  @ViewChild("alertSignup", { read: ViewContainerRef })
  alertSignup: ViewContainerRef;
  @ViewChild("alertForgotPass", { read: ViewContainerRef })
  alertForgotPass: ViewContainerRef;

  constructor(
    private _router: Router,
    private _script: ScriptLoaderService,
    private _route: ActivatedRoute,
    private auth: AuthService,
    public storageService: StorageService,
    public messageService: MessageService,
    public usuariosService: UsuariosService
  ) {}

  ngOnInit() {
    this.credentials.remember = true;
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    this._router.navigate([this.returnUrl]);

    this._script
      .load(
        "body",
        "assets/vendors/base/vendors.bundle.js",
        "assets/demo/default/base/scripts.bundle.js"
      )
      .then(() => {
        Helpers.setLoading(false);
        LoginCustom.init();
      });
  }

  login() {
    this.loading = true;
    this.auth
      .login(this.credentials.email, this.credentials.password)
      .subscribe(
        response => {
          this.auth.afterLogin(this.credentials, response);
          this._router.navigate([this.returnUrl]);
          this.messageService.success("Bienvenido a Redxar");
        },
        error => {
          if(error.error.error == 'invalid_grant')
             this.messageService.error('Inválidos usuario o contraseña');
          else
             this.messageService.error(error.error.error_description);
          this.loading = false;
        }
      );
  }

  forgotPass() {
    this.loading = true;
    this.auth.resetPassword(this.credentials.email).subscribe(
      () => {
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
}
