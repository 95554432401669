import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable()
export class PublicacionesService extends ApiService {
  entityType = EntityType.PUBLICACION_ML;
  url = 'api/marketplace/publicaciones/ml';

  cambiarEstado(resource) {
    return this.http.put(`${this.url}/update_status/${resource.mlId}`, resource);
  }
}
