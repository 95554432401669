import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable()
export class ProductoVentaService extends ApiService {
  entityType = EntityType.PRODUCTO_VENTA;
  url = 'api/producto-venta/';

  imprimirEtiqueta(id, params: any, responseType?: any) {
    return this.http.get(`${this.url}${id}/imprimir-etiqueta`, {
      params: this.object2Params(this.serialize(params)),
      responseType: responseType
    });
  }

  getKeyprov(data: any) {
    return this.http.get(`${this.url}${data.id}/proveedor/${data.inicialesProveedor}/keyprov`);
  }

  getPcs(resource) {
    return this.http.get(`${this.url}${resource.id}/articulos-compra`, {
      params: this.object2Params(this.serialize(resource))
    });
  }
  getAvs(resource) {
    return this.http.get(`${this.url}${resource.id}/articulos-venta`, {
      params: this.object2Params(this.serialize(resource))
    });
  }

  cambiarEstado(resource) {
    return this.http.put(`${this.url}cambiar-estado`, this.serialize(resource));
  }

  getReferencias(id) {
    return this.http.get(`${this.url}${id}/referencias`, { params: { plataforma_id: 1 } });
  }

  getLogisticas(id) {
    return this.http.get(`${this.url}${id}/logisticas`);
  }

  exportar(params: any, responseType?: any) {
    return this.http.get(`${this.url}exportar`, {
      params: this.object2Params(this.serialize(params)),
      responseType: responseType
    });
  }
}
