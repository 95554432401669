import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class StockLocalService extends ApiService {
  entityType = EntityType.STOCK_LOCAL;
  url = 'api/stock-local/';


  importar(formData: FormData): Observable<any> {
    return this.http.post(`${this.url}importar`, formData);
  }


  getStock(id): Observable<any> {
    id = this.serialize(id);
    return this.http.get(`${this.url}show`,{ params: this.object2Params(id) });
  }
}
