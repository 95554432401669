import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class ItemPropioService extends ApiService {
  entityType = EntityType.ITEM;
  url = 'api/seteados/itemspropio/';

  importItems(formData: FormData): Observable<any> {
    return this.http.post(`api/seteados/itemspropio/importpropio`, formData);
  }

  exportarItems(params: any, responseType?: any) {
    return this.http.get(`api/seteados/itemspropio/export/xls`, {
      params: this.object2Params(this.serialize(params)),
      responseType: 'blob'
    });
  }

  listFast(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}index-fast`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }
}
