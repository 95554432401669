import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class MercadoLibreService extends ApiService {
  public plataformaId = 1;
  entityType = EntityType.MERCADO_LIBRE;
  url = `api/mercado-libre/shops/`;

  verMensajes(id) {
    return this.http.get(`${this.url}${id}/mensajes`);
  }

  agregarMensaje(id, resource) {
    return this.http.post(`${this.url}${id}/mensajes`, resource);
  }

  getPreguntas(params?: any): Observable<any> {
    params = this.serialize(params);
    return this.http.get(`${this.url}preguntas/search`, { params: this.object2Params(params) });
  }

  getProducto(tienda_id, producto_id) {
    return this.http.get(`${this.url}${tienda_id}/producto/${producto_id}`);
  }

  getPublicaciones(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}${params.tienda_id}/products/`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }

  getOrdenes(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}${params.tienda_id}/ordenes/`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }

  cambiarEstadoPublicacion(resource) {
    return this.http.put(`${this.url}${resource.tienda_id}/products/status`, resource);
  }

  imprimirEtiquetas(params: any, responseType?: any) {
    return this.http.get(`api/mercadolibre/1/shipment_labels`, {
      params: this.object2Params(this.serialize(params)),
      responseType: 'blob'
    });
  }
}
