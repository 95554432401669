import { Injectable } from "@angular/core";
import { PermissionType } from "@app/shared/enums/permission-type.enum";
import { ObjectsPermissions } from "@app/shared/model/objects-permissions.model";
import { UserPermissions } from "@app/shared/model/permissions.model";
import { Role } from "@app/shared/model/role.model";
import { UserCredentials } from "@app/shared/model/user-credentials.model";
import { User } from "@app/shared/model/user.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { UsuariosService } from "../api/usuarios.service";
import { HttpService } from "../http/http.service";

const USER_KEY = "enkarga.user";
const ACCESS_TOKEN_KEY = "enkarga.access_token";
const REFRESH_TOKEN_KEY = "enkarga.refresh_token";
const REMEMBER_KEY = "enkarga.remember";
const EXPIRES_DATE_KEY = "enkarga.expires_date";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public user: User;
  public permissions: UserPermissions;

  constructor(
    private http: HttpService,
    private usuarioService: UsuariosService
  ) {}

  login(user: string, password: string): Observable<any> {
    let body = {
      grant_type: "password",
      client_id: "1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4",
      client_secret: "4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k",
      username: user,
      password: password
    };
    return this.http.post("oauth/v2/token", body);
  }

  logout() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(REMEMBER_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(EXPIRES_DATE_KEY);

    this.user = null;
    this.permissions = null;
  }

  afterLogin(credentials: UserCredentials, loginResponse) {
    this.setUser(credentials.email);
    this.setRemember(credentials.remember.toString());
    this.setAccessToken(loginResponse.access_token);
    this.setRefreshToken(loginResponse.refresh_token);
    this.setExpiresDate(
      this.calculateTokenExpiresAt(loginResponse.expires_in).toString()
    );

    this.getProfile();
    this.getPermissions();
  }

  refreshToken(): Observable<string> {
    let body = {
      grant_type: "refresh_token",
      client_id: "1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4",
      client_secret: "4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k",
      refresh_token: this.getRefreshToken()
    };

    return this.http.post("oauth/v2/token", body).pipe(
      share(),
      map(res => {
        this.setRefreshToken(res["refresh_token"]);
        this.setAccessToken(res["access_token"]);

        return res["access_token"];
      })
    );
  }

  getProfile() {
    if (!this.user) {
      this.usuarioService.getProfile().pipe(
        share()
      ).subscribe(user => {
        this.user = user.data;
      },
      error => {
        this.logout();
      });
    }
  }

  getPermissions() {
    if (!this.permissions) {
      this.usuarioService.getPermissions().pipe(
        share()
      ).subscribe(permissions => {
        this.permissions = permissions.data;
      });
    }
  }

  /**
   * Checks if the user can perform a certain action over a certain entity/object.
   *
   * @param permissionType
   * @param label
   * @param id
   */
  userCan(permissionType: PermissionType, label: string, id?: string): boolean {
    const entityPermissions = this.permissions[label];

    if (this.isSuperUser()) {
      return true;
    }

    if (!entityPermissions) {
      return false;
    }

    if (this.can(permissionType, entityPermissions.permissions)) {
      return true;
    }

    if (this.canObject(permissionType, entityPermissions.objects, id)) {
      return true;
    }

    return false;
  }

  private can(permissionType: PermissionType, permissions: number) {
    return permissions && permissions & permissionType;
  }

  private canObject(
    permissionType: PermissionType,
    objects: ObjectsPermissions,
    id: string
  ) {
    return id && objects && this.can(permissionType, objects[id]);
  }

  calculateTokenExpiresAt(expires_in) {
    return new Date().getTime() + expires_in * 1000;
  }

  getUser(): string {
    return localStorage.getItem(USER_KEY);
  }

  setUser(user: any) {
    localStorage.setItem(USER_KEY, user);
  }

  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  setAccessToken(access_token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  setRefreshToken(refresh_token: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  }

  getRemember(): string {
    return localStorage.getItem(REMEMBER_KEY);
  }

  setRemember(remember: string) {
    localStorage.setItem(REMEMBER_KEY, remember);
  }

  getExpiresDate(): string {
    return localStorage.getItem(EXPIRES_DATE_KEY);
  }

  setExpiresDate(expires_date: string) {
    localStorage.setItem(EXPIRES_DATE_KEY, expires_date);
  }

  isLoggedIn(): boolean {
    return this.getUser() !== null && this.getUser() !== undefined;
  }

  isSuperUser(): boolean {
    if(this.user && this.user.roles){
      return this.user.roles.some((role: Role) => {
        return role.name === "ROLE_SUPER";
      });
    }else{
      return false;
    }
  
  }

  resetPassword(email) {
    let params = { email: email };
    return this.http.getWithoutAuthHeader("api/password/recuperar", {
      params: params
    });
  }
}
