import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class SkumastersService extends ApiService {
  entityType = EntityType.SKUMASTER;
  url = 'api/marketplace/skumasters/';

  traducir(resource: any): Observable<any> {
    resource = super.serialize(resource);
    return this.http.put(`${this.url}traducir`, resource);
  }
  verificar(resource: any): Observable<any> {
    resource = super.serialize(resource);
    return this.http.put(`${this.url}verificar`, resource);
  }
  remapear(resource: any): Observable<any> {
    resource = super.serialize(resource);
    return this.http.put(`${this.url}remapear`, resource);
  }
  predecir(resource: any): Observable<any> {
    resource = super.serialize(resource);
    return this.http.put(`${this.url}predict`, resource);
  }
  autocompletar(resource: any): Observable<any> {
    resource = super.serialize(resource);
    return this.http.put(`${this.url}autocompletar`, resource);
  }
  agregarVariacion(sku_padre, sku_master) {
    sku_master = this.serialize(sku_master);
    return this.http.put(`${this.url}${sku_padre}/variacion`, sku_master);
  }
  getVariaciones(id) {
    return this.http.get(`${this.url}variaciones/${id}`);
  }
  getAtributos(id) {
    return this.http.get(`${this.url}atributos/${id}`);
  }
  getCombinaciones(id) {
    return this.http.get(`${this.url}combinaciones/${id}`);
  }
  agregarAtributo(id, resource) {
    return this.http.put(`${this.url}${id}/atributo`, resource);
  }
  actualizarCategoria(id, resource) {
    return this.http.put(`${this.url}${id}/categoria`, resource);
  }

  getReferencias(id) {
    return this.http.get(`${this.url}${id}/referencias`);
  }
}
