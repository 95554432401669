import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { AuthRoutingModule } from "./auth-routing.routing";
import { AuthComponent } from "./auth.component";
import { LogoutComponent } from "./logout/logout.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AuthService } from "../shared/services/auth/auth.service";
import { HttpService } from "../shared/services/http/http.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpAuthInterceptor} from "./http-auth-interceptor";
import {environment} from "../../environments/environment";

@NgModule({
    declarations: [
        AuthComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        AuthRoutingModule,
    ],
    providers: [
        // API url constant
        //local
        { provide: 'API_URL', useValue: environment.API_URL },
        //prod
      // { provide: 'API_URL', useValue: 'https://new-api-mp.enkarga.com/' },

        //dev
        //{ provide: 'API_URL', useValue: 'http://dev-api-mp.enkarga.com/app_dev.php/' },
        AuthGuard,
        // api backend simulation
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptor,
            multi: true
        },
        HttpService,
    ],
})

export class AuthModule {
}