import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiService {
  entityType = EntityType.NOTIFICACION;
  url = 'api/notifications/';

  readAll() {
    return this.http.put(this.url, {});
  }

  read(id) {
    return this.http.put(`${this.url}${id}`, {});
  }
}
