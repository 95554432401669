import { Injectable } from "@angular/core";
import { EntityType } from "@app/shared/enums/entity-type.enum";
import { Product } from "@app/shared/model/product.mode";
import { Observable } from "rxjs/internal/Observable";
import { ApiService } from "./api.service";

@Injectable()
export class ProductosService extends ApiService {
  entityType = EntityType.PRODUCTO;
  url = "api/marketplace/productos/";

  publicarMLMx(resource?: any): Observable<any> {
    resource = this.serialize(resource);
    return this.http.post(`${this.url}publicar/ml`, resource);
  }

  getComparaciones(id): Observable<any> {
    return this.http.get(`${this.url}comparaciones/${id}`);
  }

  getPublicaciones(id): Observable<any> {
    return this.http.get(`${this.url}publicaciones/${id}`);
  }

  listFast(
    params: { limit?: number; offset?: number; q?: string } = {}
  ): Observable<{ data: { products: Product[] } }> {
    params = this.serialize(params);
    return this.http.get(`${this.url}index-fast`, {
      params: this.object2Params(params)
    });
  }

  countFast(
    params: { limit?: number; offset?: number; q?: string } = {}
  ): Observable<{ data: { count: number } }> {
    params = this.serialize(params);
    return this.http.get(`${this.url}index-fast-count`, {
      params: this.object2Params(params)
    });
  }
}
