import { Pipe, PipeTransform } from "@angular/core";

const DAYS_OF_THE_WEEK = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado"
];

const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];

@Pipe({ name: "customDate" })
export class CustomDatePipe implements PipeTransform {
  transform(text: string): string {
    const date = new Date(text);
    
    return `${DAYS_OF_THE_WEEK[date.getDay()]}, ${date.getDate()} de ${MONTHS[date.getMonth()]}, ${date.getFullYear()}`;
  }
}
