import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable()
export class ProductoCompraService extends ApiService {
  entityType = EntityType.PRODUCTO_COMPRA;
  url = 'api/producto-compra/';

  cambiarEstado(resource) {
    return this.http.put(`${this.url}cambiar-estado`, resource);
  }

  getPcs(resource) {
    return this.http.get(`${this.url}${resource.id}/articulos-compra`, {
      params: this.object2Params(this.serialize(resource))
    });
  }

  reembolsar(resource) {
    return this.http.post(`${this.url}hacer-reembolso`, this.serialize(resource));
  }

  notificarDevolucion(resource) {
    return this.http.post(`${this.url}notificar-devolucion`, this.serialize(resource));
  }

  devolver(resource) {
    return this.http.post(`${this.url}hacer-devolucion`, this.serialize(resource));
  }

  devolverDirecto(resource) {
    return this.http.post(`${this.url}devolucion-directa`, this.serialize(resource));
  }
}
