import { AfterViewInit, Component, ViewEncapsulation } from "@angular/core";
import { AppService } from "@app/app.service";
import { EntityType } from "@app/shared/enums/entity-type.enum";
import { PermissionType } from "@app/shared/enums/permission-type.enum";
import { AuthService } from "@app/shared/services/auth/auth.service";

declare let mLayout: any;
@Component({
  selector: "app-aside-nav",
  templateUrl: "./aside-nav.component.html",
  styleUrls: ["./aside-nav.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AsideNavComponent implements AfterViewInit {
  constructor(
    public app: AppService,
    public auth: AuthService
  ) { }

  ngAfterViewInit() {
    this.auth.getProfile();
    this.auth.getPermissions();

    mLayout.initAside();
    let menu = mLayout.getAsideMenu();
    let item = $(menu)
      .find('a[href="' + window.location.pathname + '"]')
      .parent(".m-menu__item");
    (<any>$(menu).data("menu")).setActiveItem(item);
  }

  show(
    entityType: EntityType,
    permissionType: PermissionType = PermissionType.VIEW
  ) {
    return this.auth.userCan(permissionType, entityType);
  }

  showUsuarios() {
    return this.show(EntityType.USER);
  }

  showRoles() {
    return this.show(EntityType.ROLE);
  }

  showUsuariosMenu() {
    return this.showUsuarios() || this.showRoles();
  }

  showSitios() {
    return this.show(EntityType.SITIO);
  }

  showAdministration() {
    return this.showUsuariosMenu() || this.showSitios();
  }

  showCalculadora() {
    return (
      this.show(EntityType.CALCULO_IMPORTACION) &&
      this.show(EntityType.CALCULO_PLATAFORMA)
    );
  }

  showPreguntasML() {
    return true;
  }

  showOrdenes() {
    return this.show(EntityType.VENTA);
  }

  showControlDeEnvios() {
    return this.show(EntityType.PRODUCTO_VENTA);
  }

  showInventarioLocal() {
    return true;
  }

  showServicioAlCliente() {
    return (
      this.showCalculadora() ||
      this.showPreguntasML() ||
      this.showOrdenes() ||
      this.showControlDeEnvios() ||
      this.showInventarioLocal()
    );
  }

  showProveedores() {
    return this.show(EntityType.PROVEEDOR);
  }

  showItems() {
    return this.show(EntityType.ITEM);
  }

  showAmazonApi() {
    return this.show(EntityType.AMAZON_CATEGORIA);
  }

  showBestbuyApi() {
    return this.show(EntityType.BESTBUY_CATEGORIA);
  }

  showProveedoresMenu() {
    return (
      this.showProveedores() ||
      this.showItems() ||
      this.showAmazonApi() ||
      this.showBestbuyApi()
    );
  }

  showCategorias() {
    return this.show(EntityType.CATEGORIA);
  }

  showSkumaster() {
    return this.show(EntityType.SKUMASTER);
  }

  showFirstAid() {
    return this.show(EntityType.SKUMASTER);
  }

  showComparador() {
    return this.show(EntityType.PRODUCTO);
  }

  showProductos() {
    return this.show(EntityType.PRODUCTO);
  }

  showBodegas() {
    return this.show(EntityType.BODEGA);
  }

  showArticulos() {
    return this.show(EntityType.ITEM);
  }
  showErroresDePublicacion() {
    return this.show(EntityType.LOG_PUBLICACION);
  }

  showSkumakerMenu() {
    return (
      this.showCategorias() ||
      this.showSkumaster() ||
      this.showFirstAid() ||
      this.showComparador() ||
      this.showProductos() ||
      this.showErroresDePublicacion()
    );
  }

  showMarketplace() {
    return this.showProveedoresMenu() || this.showSkumaster();
  }

  showPaises() {
    return this.show(EntityType.PAIS);
  }

  showZonas() {
    return true /*this.show(EntityType.ZONA)*/;
  }

  showProvincias() {
    return true /*this.show(EntityType.PROVINCIA)*/;
  }

  showCiudades() {
    return true /*this.show(EntityType.CIUDAD)*/;
  }

  showMonedas() {
    return this.show(EntityType.MONEDA);
  }

  showPlantillas() {
    return this.show(EntityType.PLANTILLA);
  }

  showPasarelas() {
    return this.show(EntityType.PASARELA);
  }

  showReglasPublicacion() {
    return this.show(EntityType.REGLA_PUBLICACION);
  }

  showReglasStock() {
    return this.show(EntityType.PLATAFORMA);
  }

  showCorreos() {
    return true /*this.show(EntityType.MAIL)*/;
  }

  showConfiguracionMenu() {
    return (
      this.showPaises() ||
      this.showZonas() ||
      this.showProvincias() ||
      this.showCiudades() ||
      this.showMonedas() ||
      this.showPlantillas() ||
      this.showPasarelas() ||
      this.showReglasPublicacion() ||
      this.showReglasStock() ||
      this.showCorreos() ||
      this.showBodegas()
    );
  }

  showCalculos() {
    return this.show(EntityType.CALCULO_IMPORTACION);
  }

  showEmpresas() {
    return true;
  }

  showDimensionesCajas() {
    return this.show(EntityType.DIMENSION_CAJA);
  }

  showTarifarios() {
    return true;
  }

  showAranceles() {
    return true;
  }

  showTrackings() {
    return this.show(EntityType.TRACKING);
  }

  showEnviosMenu() {
    return (
      this.showCalculos() ||
      this.showEmpresas() ||
      this.showDimensionesCajas() ||
      this.showTarifarios() ||
      this.showAranceles() ||
      this.showTrackings()
    );
  }

  showLogistica() {
    return (
      this.show(EntityType.LOGISTICA) && this.show(EntityType.PRODUCTO_VENTA)
    );
  }

  showCompras() {
    return this.show(EntityType.COMPRA);
  }

  showStockLocal() {
    // return this.show(EntityType.TRACKING);
    return true;
  }

  showBodegaMenu() {
    return this.showLogistica() || this.showCompras() || this.showStockLocal();
  }

  showCrossborder() {
    return this.showConfiguracionMenu() || this.showEnviosMenu();
  }

  showClientesMarketplace() {
    return this.show(EntityType.CLIENTE);
  }

  showAppEnkarga() {
    return this.show(EntityType.CLIENTE);
  }

  showClientesMenu() {
    return this.showClientesMarketplace() || this.showAppEnkarga();
  }

  showIntegrador() {
    return this.showClientesMenu();
  }

  showCalculoPV() {
    return this.show(EntityType.CALCULO_PLATAFORMA);
  }

  showTiendasMercadoLibre() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showOrdenesMercadoLibre() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showPublicacionesMercadoLibre() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showMercadoLibreMenu() {
    return (
      this.showTiendasMercadoLibre() ||
      this.showOrdenesMercadoLibre() ||
      this.showPublicacionesMercadoLibre()
    );
  }

  showTiendasShopify() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showOrdenesShopify() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showPublicacionesShopify() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showShopifyMenu() {
    return (
      this.showTiendasShopify() ||
      this.showOrdenesShopify() ||
      this.showPublicacionesShopify()
    );
  }

  showPublicarWoocommerce() {
    // return this.show(EntityType.CLIENTE);
    return true;
  }

  showWoocommerceMenu() {
    return this.showPublicarWoocommerce();
  }

  showPlataformas() {
    return (
      this.showCalculoPV() ||
      this.showMercadoLibreMenu() ||
      this.showShopifyMenu() ||
      this.showWoocommerceMenu()
    );
  }
}
