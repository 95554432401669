import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class ErroresPublicacionService extends ApiService {
  entityType = EntityType.ERROR_LOG;
  url = 'api/marketplace/productos/errores-log';

  listFast(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}-fast`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }
}
