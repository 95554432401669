import { Pipe, PipeTransform } from '@angular/core';

/*
 * Transforms a snakecase string to a title
 * Example: a_snake_case_string -> A Snake Case String
 */
@Pipe({ name: 'snakeCase2Title' })
export class SnakeCase2TitlePipe implements PipeTransform {
  transform(text: string): string {
    let words = text.split('_').map((word: string) => {
      const firstLetter = word.substr(0, 1);
      const restOfTheWord = word.substr(1)
      return firstLetter.toUpperCase() + restOfTheWord.toLowerCase();
    })

    return words.join(' ');
  }
}
