import { Injectable } from "@angular/core";
import { EntityType } from "@app/shared/enums/entity-type.enum";
import { ChangePassword } from "@app/shared/model/change-password.model";
import { User } from "@app/shared/model/user.model";
import { Observable } from "rxjs/internal/Observable";
import { ApiService } from "./api.service";

@Injectable()
export class UsuariosService extends ApiService {
  entityType = EntityType.USER;
  url = "api/usuarios/";

  getProfile(): Observable<{data: User}> {
    return this.http.get(`${this.url}perfil`);
  }

  updateProfile(user: User): Observable<{data: User}> {
    return this.http.put(`${this.url}perfil`, user);
  }

  getPermissions(): Observable<any> {
    return this.http.get(`${this.url}permissions`);
  }

  updatePassword(passwords: ChangePassword) {
    return this.http.put(`${this.url}password`, passwords);
  }

  recuperarPassword(resource) {
    return this.http.get(`api/password/recuperar`, { params: resource });
  }
}
