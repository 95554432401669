import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable()
export class CategoriasService extends ApiService {
  entityType = EntityType.CATEGORIA;
  url = 'api/categorias/';

  getPadres() {
    return this.http.get(`${this.url}padres`);
  }

  getHijos(id) {
    return this.http.get(`${this.url}hijas/${id}`);
  }

  getPadre(id) {
    return this.http.get(`${this.url}padre-inmediato/${id}`);
  }

  listUsadas() {
    return this.http.get(`${this.url}usadas`);
  }
}
