import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class EstadisticasService extends ApiService {
  entityType = EntityType.ESTADISTICAS;
  url = 'api/estadisticas/';

  getVentas(): Observable<any> {
    return this.http.get(`${this.url}ventas`);
  }

  getCompras(): Observable<any> {
    return this.http.get(`${this.url}compras`);
  }

  getPreguntas(): Observable<any> {
    return this.http.get(`${this.url}preguntas_ml`);
  }
}
