import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/_guards/auth.guard';
import { ThemeComponent } from './theme.component';

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    children: [
      {
        path: 'index',
        loadChildren: './pages/default/index/index.module#IndexModule',
        canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
      },

      //Usuarios Begin
      {
        path: 'usuarios',
        loadChildren:
          './pages/default/usuarios/usuarios-lista/usuarios-lista.module#UsuariosListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'usuarios/crear',
        loadChildren:
          './pages/default/usuarios/crear-usuario/crear-usuario.module#CrearUsuarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'usuarios/:id',
        loadChildren:
          './pages/default/usuarios/usuario-detalles/usuario-detalles.module#UsuarioDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'usuarios/:id/actualizar',
        loadChildren:
          './pages/default/usuarios/actualizar-usuario/actualizar-usuario.module#ActualizarUsuarioModule',
        canActivate: [AuthGuard]
      },
      //Usuarios END

      //Roles BEGIN
      {
        path: 'roles',
        loadChildren: './pages/default/roles/roles-lista/roles-lista.module#RolesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'roles/crear',
        loadChildren: './pages/default/roles/crear-role/crear-role.module#CrearRoleModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'roles/:id',
        loadChildren: './pages/default/roles/role-detalles/role-detalles.module#RoleDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'roles/:id/actualizar',
        loadChildren:
          './pages/default/roles/actualizar-role/actualizar-role.module#ActualizarRoleModule',
        canActivate: [AuthGuard]
      },
      //Roles END

      //Sitios BEGIN
      {
        path: 'sitios',
        loadChildren: './pages/default/sitios/sitios-lista/sitios-lista.module#SitiosListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sitios/crear',
        loadChildren: './pages/default/sitios/crear-sitio/crear-sitio.module#CrearSitioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sitios/:id',
        loadChildren: './pages/default/sitios/sitio-detalles/sitio-detalles.module#SitioDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sitios/:id/actualizar',
        loadChildren:
          './pages/default/sitios/actualizar-sitio/actualizar-sitio.module#ActualizarSitioModule',
        canActivate: [AuthGuard]
      },
      //Sitios END

      //Proveedores BEGIN
      {
        path: 'proveedores',
        loadChildren: './pages/default/proveedores/proveedores-lista/proveedores-lista.module#ProveedoresListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'proveedores/crear',
        loadChildren: './pages/default/proveedores/crear-proveedor/crear-proveedor.module#CrearProveedorModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'proveedores/:id/actualizar',
        loadChildren: './pages/default/proveedores/actualizar-proveedor/actualizar-proveedor.module#ActualizarProveedorModule',
        canActivate: [AuthGuard]
      },
      //Proveedores END

      // DHL BEGIN
      {
        path: 'dhl/get-tracking',
        loadChildren: './pages/default/dhl/get-tracking/get-tracking.module#GetTrackingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'dhl/tracking',
        loadChildren: './pages/default/dhl/tracking/tracking-dhl.module#TrackingDHLModule',
        canActivate: [AuthGuard]
      },
        // DHL END

      //Items BEGIN
      {
        path: 'marketplace/items',
        loadChildren: './pages/default/items/items-lista/items-lista.module#ItemsListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/items/:id',
        loadChildren: './pages/default/items/item-detalles/item-detalles.module#ItemDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/items/:id/actualizar',
        loadChildren: './pages/default/items/actualizar-item/actualizar-item.module#ActualizarItemModule',
        canActivate: [AuthGuard]
      },
      //Items END

        //Articulos BEGIN
        {
          path: 'marketplace/articulos',
          loadChildren: './pages/default/articulos/articulo-lista/articulo-lista.module#ArticuloListaModule',
          canActivate: [AuthGuard]
        },
        {
          path: 'marketplace/articulos/crear',
          loadChildren: './pages/default/items/crear-item/crear-item.module#CrearItemModule',
          canActivate: [AuthGuard] 
        },
        {
          path: 'marketplace/articulos/:id',
          loadChildren: './pages/default/articulos/articulo-detalles/articulo-detalles.module#ArticuloDetallesModule',
          canActivate: [AuthGuard]
        },
        {
          path: 'marketplace/articulos/:id/actualizar',
          loadChildren: './pages/default/articulos/actualizar-articulo/actualizar-articulo.module#ActualizarArticuloModule',
          canActivate: [AuthGuard]
        }, 
        //Items END

      //Paises BEGIN
      {
        path: 'paises',
        loadChildren: './pages/default/paises/paises-lista/paises-lista.module#PaisesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'paises/crear',
        loadChildren: './pages/default/paises/crear-pais/crear-pais.module#CrearPaisModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'paises/:id/actualizar',
        loadChildren: './pages/default/paises/actualizar-pais/actualizar-pais.module#ActualizarPaisModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'paises/:id',
        loadChildren: './pages/default/paises/pais-detalles/pais-detalles.module#PaisDetallesModule',
        canActivate: [AuthGuard]
      },
      //Paises END

      // Mail Begin
      {
        path: 'mails',
        loadChildren: './pages/default/mail/mail-lista/mail-lista.module#MailListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mails/crear',
        loadChildren: './pages/default/mail/crear-mail/crear-mail.module#CrearMailModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mails/:id/actualizar',
        loadChildren: './pages/default/mail/actualizar-mail/actualizar-mail.module#ActualizarMailModule',
        canActivate: [AuthGuard]
      },
      // Mail End

      //Monedas BEGIN
      {
        path: 'monedas',
        loadChildren:
          './pages/default/monedas/monedas-lista/monedas-lista.module#MonedasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monedas/crear',
        loadChildren: './pages/default/monedas/crear-moneda/crear-moneda.module#CrearMonedaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monedas/:id/actualizar',
        loadChildren:
          './pages/default/monedas/actualizar-moneda/actualizar-moneda.module#ActualizarMonedaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monedas/:id',
        loadChildren:
          './pages/default/monedas/moneda-detalles/moneda-detalles.module#MonedaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Monedas END

      //Zonas BEGIN
      {
        path: 'zonas',
        loadChildren: './pages/default/zonas/zonas-lista/zonas-lista.module#ZonasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'zonas/crear',
        loadChildren: './pages/default/zonas/crear-zona/crear-zona.module#CrearZonaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'zonas/:id/actualizar',
        loadChildren:
          './pages/default/zonas/actualizar-zona/actualizar-zona.module#ActualizarZonaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'zonas/:id',
        loadChildren: './pages/default/zonas/zona-detalles/zona-detalles.module#ZonaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Zonas END

      //provincias BEGIN
      {
        path: 'provincias',
        loadChildren:
          './pages/default/provincias/provincias-lista/provincias-lista.module#ProvinciasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'provincias/crear',
        loadChildren:
          './pages/default/provincias/crear-provincia/crear-provincia.module#CrearProvinciaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'provincias/:id/actualizar',
        loadChildren:
          './pages/default/provincias/actualizar-provincia/actualizar-provincia.module#ActualizarProvinciaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'provincias/:id',
        loadChildren:
          './pages/default/provincias/provincia-detalles/provincia-detalles.module#ProvinciaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Provincias END

      //Ciudades BEGIN
      {
        path: 'ciudades',
        loadChildren:
          './pages/default/ciudades/ciudades-lista/ciudades-lista.module#CiudadesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ciudades/crear',
        loadChildren: './pages/default/ciudades/crear-ciudad/crear-ciudad.module#CrearCiudadModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ciudades/:id/actualizar',
        loadChildren:
          './pages/default/ciudades/actualizar-ciudad/actualizar-ciudad.module#ActualizarCiudadModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ciudades/:id',
        loadChildren:
          './pages/default/ciudades/ciudad-detalles/ciudad-detalles.module#CiudadDetallesModule',
        canActivate: [AuthGuard]
      },
      //Ciudades END

      //Plantillas BEGIN
      {
        path: 'plantillas',
        loadChildren:
          './pages/default/plantillas/plantillas-lista/plantillas-lista.module#PlantillasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'plantillas/crear',
        loadChildren:
          './pages/default/plantillas/crear-plantilla/crear-plantilla.module#CrearPlantillaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'plantillas/:id/actualizar',
        loadChildren:
          './pages/default/plantillas/actualizar-plantilla/actualizar-plantilla.module#ActualizarPlantillaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'plantillas/:id',
        loadChildren:
          './pages/default/plantillas/plantilla-detalles/plantilla-detalles.module#PlantillaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Plantillas END

      //Pasarelas BEGIN
      {
        path: 'pasarelas',
        loadChildren:
          './pages/default/pasarelas/pasarelas-lista/pasarelas-lista.module#PasarelasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'pasarelas/crear',
        loadChildren:
          './pages/default/pasarelas/crear-pasarela/crear-pasarela.module#CrearPasarelaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'pasarelas/:id/actualizar',
        loadChildren:
          './pages/default/pasarelas/actualizar-pasarela/actualizar-pasarela.module#ActualizarPasarelaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'pasarelas/:id',
        loadChildren:
          './pages/default/pasarelas/pasarela-detalles/pasarela-detalles.module#PasarelaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Pasarelas END

      //Empresas de Envío BEGIN
      {
        path: 'empresas-envio',
        loadChildren:
          './pages/default/empresas-envio/empresas-envio-lista/empresas-envio-lista.module#EmpresasEnvioListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'empresas-envio/crear',
        loadChildren:
          './pages/default/empresas-envio/crear-empresa-envio/crear-empresa-envio.module#CrearEmpresaEnvioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'empresas-envio/:id/actualizar',
        loadChildren:
          './pages/default/empresas-envio/actualizar-empresa-envio/actualizar-empresa-envio.module#ActualizarEmpresaEnvioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'empresas-envio/:id',
        loadChildren:
          './pages/default/empresas-envio/empresa-envio-detalles/empresa-envio-detalles.module#EmpresaEnvioDetallesModule',
        canActivate: [AuthGuard]
      },
      //Empresas de Envio END

      //Cajas BEGIN
      {
        path: 'dimensiones-cajas',
        loadChildren: './pages/default/cajas/cajas-lista/cajas-lista.module#CajasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'dimensiones-cajas/crear',
        loadChildren: './pages/default/cajas/crear-caja/crear-caja.module#CrearCajaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'dimensiones-cajas/:id/actualizar',
        loadChildren:
          './pages/default/cajas/actualizar-caja/actualizar-caja.module#ActualizarCajaModule',
        canActivate: [AuthGuard]
      },
      //Cajas END

      //Tarifarios BEGIN
      {
        path: 'tarifarios',
        loadChildren:
          './pages/default/tarifarios/tarifarios-lista/tarifarios-lista.module#TarifariosListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tarifarios/crear',
        loadChildren:
          './pages/default/tarifarios/crear-tarifario/crear-tarifario.module#CrearTarifarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tarifarios/:id/actualizar',
        loadChildren:
          './pages/default/tarifarios/actualizar-tarifario/actualizar-tarifario.module#ActualizarTarifarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tarifarios/:id',
        loadChildren:
          './pages/default/tarifarios/tarifario-detalles/tarifario-detalles.module#TarifarioDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tarifarios/:id/crear-detalle',
        loadChildren:
          './pages/default/tarifarios/crear-detalle-tarifario/crear-detalle-tarifario.module#CrearDetalleTarifarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tarifarios/:id/actualizar-detalle/:detalle_id',
        loadChildren:
          './pages/default/tarifarios/actualizar-detalle-tarifario/actualizar-detalle-tarifario.module#ActualizarDetalleTarifarioModule',
        canActivate: [AuthGuard]
      },
      //Tarifarios END

      //Aranceles BEGIN
      {
        path: 'aranceles',
        loadChildren:
          './pages/default/aranceles/aranceles-lista/aranceles-lista.module#ArancelesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'aranceles/crear',
        loadChildren:
          './pages/default/aranceles/crear-arancel/crear-arancel.module#CrearArancelModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'aranceles/:id/actualizar',
        loadChildren:
          './pages/default/aranceles/actualizar-arancel/actualizar-arancel.module#ActualizarArancelModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'aranceles/:id',
        loadChildren:
          './pages/default/aranceles/arancel-detalles/arancel-detalles.module#ArancelDetallesModule',
        canActivate: [AuthGuard]
      },
      //Aranceles END

      //Amazon BEGIN
      {
        path: 'marketplace/amazon',
        loadChildren:
          './pages/default/amazon/amazon-search/amazon-search.module#AmazonSearchModule',
        canActivate: [AuthGuard]
      },
      //Amazon END

      //Bestbuy BEGIN
      {
        path: 'marketplace/bestbuy',
        loadChildren:
          './pages/default/bestbuy/bestbuy-search/bestbuy-search.module#BestbuySearchModule',
        canActivate: [AuthGuard]
      },
      //Bestbuy END

      //Tipos de plataforma BEGIN
      {
        path: 'tipos-plataforma',
        loadChildren:
          './pages/default/tipos-plataforma/tipos-plataforma-lista/tipos-plataforma-lista.module#TiposPlataformaListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tipos-plataforma/crear',
        loadChildren:
          './pages/default/tipos-plataforma/crear-tipo-plataforma/crear-tipo-plataforma.module#CrearTipoPlataformaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tipos-plataforma/:id/actualizar',
        loadChildren:
          './pages/default/tipos-plataforma/actualizar-tipo-plataforma/actualizar-tipo-plataforma.module#ActualizarTipoPlataformaModule',
        canActivate: [AuthGuard]
      },
      //Aranceles END

      //Clientes BEGIN
      {
        path: 'clientes',
        loadChildren:
          './pages/default/clientes/clientes-lista/clientes-lista.module#ClientesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'clientes/crear',
        loadChildren:
          './pages/default/clientes/crear-cliente/crear-cliente.module#CrearClienteModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'clientes/:id',
        loadChildren:
          './pages/default/clientes/cliente-detalles/cliente-detalles.module#ClienteDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'clientes/:id/actualizar',
        loadChildren:
          './pages/default/clientes/actualizar-cliente/actualizar-cliente.module#ActualizarClienteModule',
        canActivate: [AuthGuard]
      },
      //Clientes END
      //Clientes BEGIN
      {
        path: 'clientes-app',
        loadChildren:
          './pages/default/clientes-casillero/clientes-casillero-lista/clientes-casillero-lista.module#ClientesCasilleroListaModule',
        canActivate: [AuthGuard]
      },

      //Clientes END

      //Plataformas BEGIN
      {
        path: 'plataformas',
        loadChildren:
          './pages/default/plataformas/plataformas-lista/plataformas-lista.module#PlataformasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'plataformas/crear',
        loadChildren:
          './pages/default/plataformas/crear-plataforma/crear-plataforma.module#CrearPlataformaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'plataformas/:id/actualizar',
        loadChildren:
          './pages/default/plataformas/actualizar-plataforma/actualizar-plataforma.module#ActualizarPlataformaModule',
        canActivate: [AuthGuard]
      },
      //Plataformas END

      //First Aid BEGIN
      {
        path: 'marketplace/skumasters/first-aid',
        loadChildren:
          './pages/default/first-aid/first-aid-lista/first-aid-lista.module#FirstAidListaModule',
        canActivate: [AuthGuard]
      },
      //First Aid END

      //Profile BEGIN
      {
        path: 'perfil',
        loadChildren: './pages/default/perfil/perfil.module#PerfilModule',
        canActivate: [AuthGuard]
      },
      //Profile END

      //Skumasters BEGIN
      {
        path: 'marketplace/skumasters',
        loadChildren:
          './pages/default/skumasters/skumasters-lista/skumasters-lista.module#SkumastersListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/skumasters/:id/actualizar',
        loadChildren:
          './pages/default/skumasters/actualizar-skumaster/actualizar-skumaster.module#ActualizarSkumasterModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/skumasters/:id/variacion',
        loadChildren:
          './pages/default/skumasters/skumaster-variacion/skumaster-variacion.module#SkumasterVariacionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/skumasters/:id',
        loadChildren:
          './pages/default/skumasters/skumaster-detalles/skumaster-detalles.module#SkumasterDetallesModule',
        canActivate: [AuthGuard]
      },
      //Skumasters END

      //Comparador BEGIN
      {
        path: 'marketplace/comparador',
        loadChildren:
          './pages/default/comparador/comparador-lista/comparador-lista.module#ComparadorListaModule',
        canActivate: [AuthGuard]
      },
      //Comparador END
      //Productos BEGIN
      {
        path: 'marketplace/productos',
        loadChildren:
          './pages/default/productos/productos-lista/productos-lista.module#ProductosListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/productos/:id',
        loadChildren:
          './pages/default/productos/producto-detalles/producto-detalles.module#ProductoDetallesModule',
        canActivate: [AuthGuard]
      },
      //Productos END

      //Errores Publicacion BEGIN
      {
        path: 'marketplace/errores-log',
        loadChildren:
          './pages/default/errores-publicacion/errores-publicacion-lista/errores-publicacion-lista.module#ErroresPublicacionListaModule',
        canActivate: [AuthGuard]
      },
      //Errores Publicacion END

      //Calculos Importacion BEGIN
      {
        path: 'calculos-importacion',
        loadChildren:
          './pages/default/calculos-importacion/calculos-importacion-lista/calculos-importacion-lista.module#CalculosImportacionListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'calculos-importacion/crear',
        loadChildren:
          './pages/default/calculos-importacion/crear-calculo-importacion/crear-calculo-importacion.module#CrearCalculoImportacionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'calculos-importacion/:id',
        loadChildren:
          './pages/default/calculos-importacion/calculo-importacion-detalles/calculo-importacion-detalles.module#CalculoImportacionDetallesModule',
        canActivate: [AuthGuard]
      },

      //Calculos Importacion END

      //Calculos Plataforma BEGIN
      {
        path: 'calculos-plataforma',
        loadChildren:
          './pages/default/calculos-plataforma/calculos-plataforma-lista/calculos-plataforma-lista.module#CalculosPlataformaListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'calculos-plataforma/crear',
        loadChildren:
          './pages/default/calculos-plataforma/crear-calculo-plataforma/crear-calculo-plataforma.module#CrearCalculoPlataformaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'calculos-plataforma/:id',
        loadChildren:
          './pages/default/calculos-plataforma/calculo-plataforma-detalles/calculo-plataforma-detalles.module#CalculoPlataformaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Calculos Plataforma END
      //Reglas de Publicacion BEGIN
      {
        path: 'reglas-publicacion',
        loadChildren:
          './pages/default/reglas-publicacion/reglas-publicacion-lista/reglas-publicacion-lista.module#ReglasPublicacionListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reglas-publicacion/crear',
        loadChildren:
          './pages/default/reglas-publicacion/crear-regla-publicacion/crear-regla-publicacion.module#CrearReglaPublicacionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reglas-publicacion/:id/actualizar',
        loadChildren:
          './pages/default/reglas-publicacion/actualizar-regla-publicacion/actualizar-regla-publicacion.module#ActualizarReglaPublicacionModule',
        canActivate: [AuthGuard]
      },
      //Reglas de Publicacion END
       //Bodega BEGIN
       {
        path: 'bodegas',
        loadChildren:
          './pages/default/bodegas/bodegas-lista/bodegas-lista.module#BodegasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'bodegas/crear',
        loadChildren:
          './pages/default/bodegas/crear-bodega/crear-bodega.module#CrearBodegaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'bodegas/:id/actualizar',
        loadChildren:
          './pages/default/bodegas/actualizar-bodega/actualizar-bodega.module#ActualizarBodegaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'bodegas/:id',
        loadChildren:
          './pages/default/bodegas/bodega-detalles/bodega-detalles.module#BodegaDetallesModule',
        canActivate: [AuthGuard]
      },
      //Bodegas END
      //Reglas de Stock BIGIN
      {
        path: 'reglas-stock',
        loadChildren:
          './pages/default/reglas-stock/reglas-stock-lista/reglas-stock-lista.module#ReglasStockListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reglas-stock/:id/actualizar',
        loadChildren:
          './pages/default/reglas-stock/actualizar-regla-stock/actualizar-regla-stock.module#ActualizarReglaStockModule',
        canActivate: [AuthGuard]
      },
      //Reglas de Stock END
      //Ordenes BEGIN
      {
        path: 'ordenes',
        loadChildren:
          './pages/default/ordenes/ordenes-lista/ordenes-lista.module#OrdenesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ordenes/crear',
        loadChildren: './pages/default/ordenes/crear-orden/crear-orden.module#CrearOrdenModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ordenes/:id/actualizar',
        loadChildren:
          './pages/default/ordenes/actualizar-orden/actualizar-orden.module#ActualizarOrdenModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ordenes/:id',
        loadChildren:
          './pages/default/ordenes/orden-detalles/orden-detalles.module#OrdenDetallesModule',
        canActivate: [AuthGuard]
      },
      //Ordenes END

      //MERCADOLIBRE BEGIN
      {
        path: 'mercadolibre/shops',
        loadChildren:
          './pages/default/mercadolibre/tiendas/lista/mercadolibre-tiendas-lista.module#MercadolibreTiendasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mercadolibre/shops/crear',
        loadChildren:
          './pages/default/mercadolibre/tiendas/crear/mercadolibre-crear-tienda.module#MercadolibreCrearTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mercadolibre/shops/:id/actualizar',
        loadChildren:
          './pages/default/mercadolibre/tiendas/actualizar/mercadolibre-actualizar-tienda.module#MercadolibreActualizarTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mercadolibre/ordenes',
        loadChildren:
          './pages/default/mercadolibre/ordenes/ml-ordenes-lista/ml-ordenes-lista.module#MLOrdenesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mercadolibre/publicaciones',
        loadChildren:
          './pages/default/mercadolibre/publicaciones/publicaciones-lista/publicaciones-lista.module#PublicacionesListaModule',
        canActivate: [AuthGuard]
      },
      //MERCADOLIBRE END

      //SHOPIFY BEGIN
      {
        path: 'shopify/shops',
        loadChildren:
          './pages/default/shopify/tiendas/lista/shopify-tiendas-lista.module#ShopifyTiendasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'shopify/shops/crear',
        loadChildren:
          './pages/default/shopify/tiendas/crear/shopify-crear-tienda.module#ShopifyCrearTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'shopify/shops/:id/actualizar',
        loadChildren:
          './pages/default/shopify/tiendas/actualizar/shopify-actualizar-tienda.module#ShopifyActualizarTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'shopify/publicaciones',
        loadChildren:
          './pages/default/shopify/publicaciones/publicaciones-shopify-lista/publicaciones-shopify-lista.module#PublicacionesShopifyListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'shopify/ordenes',
        loadChildren:
          './pages/default/shopify/ordenes/shopify-ordenes-lista/shopify-ordenes-lista.module#ShopifyOrdenesListaModule',
        canActivate: [AuthGuard]
      },
      //SHOPIFY END

      //Compras BEGIN
      {
        path: 'compras',
        loadChildren:
          './pages/default/compras/compras-lista/compras-lista.module#ComprasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'compras/crear',
        loadChildren: './pages/default/compras/crear-compra/crear-compra.module#CrearCompraModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'compras/:id/actualizar',
        loadChildren:
          './pages/default/compras/actualizar-compra/actualizar-compra.module#ActualizarCompraModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'compras/:id',
        loadChildren:
          './pages/default/compras/compra-detalles/compra-detalles.module#CompraDetallesModule',
        canActivate: [AuthGuard]
      },
      //Compras END

       //Woocommerce BEGIN
       {
        path: 'woo/shops',
        loadChildren:
          './pages/default/woocommerce/tiendas/lista/woocommerce-tiendas-lista.module#WoocommerceTiendasListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'woo/shops/crear',
        loadChildren:
          './pages/default/woocommerce/tiendas/crear/woocommerce-crear-tienda.module#WoocommerceCrearTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'woo/shops/:id/actualizar',
        loadChildren:
          './pages/default/woocommerce/tiendas/actualizar/woocommerce-actualizar-tienda.module#WoocommerceActualizarTiendaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'woo/publicar',
        loadChildren:
          './pages/default/woocommerce/publicaciones/publicaciones-woo-lista/publicaciones-woo-lista.module#PublicacionesWooListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'woo/ordenes',
        loadChildren:
          './pages/default/woocommerce/ordenes/woocommerce-ordenes-lista/woocommerce-ordenes-lista.module#WoocommerceOrdenesListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'woo/publicar/excell',
        loadChildren:
          './pages/default/woocommerce/woocommerce-publicar/woocommerce-publicar.module#WoocommercePublicarModule',
        canActivate: [AuthGuard]
      },
      //Woocommerce END

      //Calculadora BEGIN
      {
        path: 'calculadora',
        loadChildren: './pages/default/calculadora/calculadora.module#CalculadoraModule',
        canActivate: [AuthGuard]
      },
      //Calculadora END

      //Categorias BEGIN
      {
        path: 'categorias',
        loadChildren:
          './pages/default/categorias/categorias-lista/categorias-lista.module#CategoriasListaModule',
        canActivate: [AuthGuard]
      },
      /* {
                 "path": "categorias/crear",
                 "loadChildren": ".\/pages\/default\/categorias\/crear-categoria\/crear-categoria.module#CrearCategoriaModule"
             },
             {
                 "path": "categorias/:id/actualizar",
                 "loadChildren": ".\/pages\/default\/categorias\/actualizar-categoria\/actualizar-categoria.module#ActualizarCategoriaModule"
             },*/
      //Categorias END

      //Logistica BEGIN
      {
        path: 'logistica',
        loadChildren:
          './pages/default/logistica/logistica-lista/logistica-lista.module#LogisticaListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'logistica/crear',
        loadChildren:
          './pages/default/logistica/crear-logistica/crear-logistica.module#CrearLogisticaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'logistica/:id',
        loadChildren:
          './pages/default/logistica/logistica-detalles/logistica-detalles.module#LogisticaDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'productos-venta/:id',
        loadChildren:
          './pages/default/logistica/producto-venta-detalles/producto-venta-detalles.module#ProductoVentaDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'logistica/:id/actualizar',
        loadChildren:
          './pages/default/logistica/actualizar-logistica/actualizar-logistica.module#ActualizarLogisticaModule',
        canActivate: [AuthGuard]
      },
      //Logistica END

      //Manifiesto BEGIN
      {
        path: 'manifiesto',
        loadChildren:
          './pages/default/manifiesto/manifiesto-lista/manifiesto-lista.module#ManifiestoListaModule',
        canActivate: [AuthGuard]
      },
      //Manifiesto END

      //Trackings BEGIN
      {
        path: 'trackings',
        loadChildren:
          './pages/default/trackings/trackings-lista/trackings-lista.module#TrackingsListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'trackings/crear',
        loadChildren:
          './pages/default/trackings/crear-tracking/crear-tracking.module#CrearTrackingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'trackings/:id/actualizar',
        loadChildren:
          './pages/default/trackings/actualizar-tracking/actualizar-tracking.module#ActualizarTrackingModule',
        canActivate: [AuthGuard]
      },
      //Trackings END

      //StockLocal BEGIN
      {
        path: 'stock-local',
        loadChildren:
          './pages/default/stock-local/stock-local-lista/stock-local-lista.module#StockLocalListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local-mx',
        loadChildren:
          './pages/default/stock-local/stock-local-lista/stock-local-lista.module#StockLocalListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local/crear',
        loadChildren:
          './pages/default/stock-local/crear-stock-local/crear-stock-local.module#CrearStockLocalModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local-mx/crear',
        loadChildren:
          './pages/default/stock-local/crear-stock-local/crear-stock-local.module#CrearStockLocalModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local/:id',
        loadChildren:
          './pages/default/stock-local/stock-local-detalles/stock-local-detalles.module#StockLocalDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local-mx/:id',
        loadChildren:
          './pages/default/stock-local/stock-local-detalles/stock-local-detalles.module#StockLocalDetallesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local/:id/actualizar',
        loadChildren:
          './pages/default/stock-local/actualizar-stock-local/actualizar-stock-local.module#ActualizarStockLocalModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'stock-local-mx/:id/actualizar',
        loadChildren:
          './pages/default/stock-local/actualizar-stock-local/actualizar-stock-local.module#ActualizarStockLocalModule',
        canActivate: [AuthGuard]
      },
      //StockLocal END
      // Preguntas ml begin
      {
        path: 'mercadolibre/preguntas',
        loadChildren:
          './pages/default/preguntas-ml/preguntas-ml-lista/preguntas-ml-lista.module#PreguntasMlListaModule',
        canActivate: [AuthGuard]
      },
      //Preguntas ml end

      //Precio-venta begin
      {
        path: 'marketplace/producto/precioventa',
        loadChildren: './pages/default/precioventa/precioventa-lista/precioventa-lista.module#PrecioventaListaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/producto/precioventageneral',
        loadChildren: './pages/default/precioventa/crear-precioventa-general/crear-precioventa-general.module#CrearPrecioventaGeneralModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/producto/precioventa/crear',
        loadChildren: './pages/default/precioventa/crear-precioventa/crear-precioventa.module#CrearPrecioventaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'marketplace/producto/precioventa/:id/actualizar',
        loadChildren: './pages/default/precioventa/actualizar-precioventa/actualizar-precioventa.module#ActualizarPrecioventaModule',
        canActivate: [AuthGuard]
      },
      //Precio-venta end
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule {}
