import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/index';
import { ApiService } from './api.service';

@Injectable()
export class ShopifyService extends ApiService {
  entityType = EntityType.SHOPIFY;
  url = 'api/shopify/shops/';

  publicar(resource?: any): Observable<any> {
    resource = this.serialize(resource);
    return this.http.post(`${this.url}${resource.id}/products`, resource);
  }

  getPublicaciones(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}${params.tienda_id}/products`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }

  getOrdenes(params?: any, responseType?: any): Observable<any> {
    if (!params) return this.http.get(this.url);
    params = this.serialize(params);
    return this.http.get(`${this.url}${params.tienda_id}/orders`, {
      params: this.object2Params(params),
      responseType: responseType
    });
  }

  sincronizarOrden(resource?: any): Observable<any> {
    resource = this.serialize(resource);
    return this.http.post(`${this.url}${resource.shop_id}/orders/${resource.order_id}`, resource);
  }
}
