import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UsuariosService } from '@app/shared/services/api/usuarios.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { NotificationService } from '@app/shared/services/socket/notification.service';
import { AppService } from '../../../app.service';

declare let mLayout: any;
@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
  constructor(
    public appService: AppService,
    public auth: AuthService,
    public usuariosService: UsuariosService,
    public notificationService: NotificationService
  ) {}
  ngOnInit() {}

  ngAfterViewInit() {
    mLayout.initHeader();
  }

  getEstadisticas() {
    this.appService.getEstadisticas();
  }

  getProfile() {
    this.auth.getProfile();
  }

  onScroll() {
    let div = document.getElementById('dropdownDiv');

    if (div.offsetHeight + div.scrollTop >= div.scrollHeight) {
    }
  }
}
