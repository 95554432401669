import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/index';

@Injectable()
export class WoocommerceService extends ApiService {
    entityType = EntityType.WOOCOMMERCE;
    url = 'api/marketplace/woo/shops/';

    publicar(resource?: any): Observable<any> {
        resource = this.serialize(resource);
        return this.http.post(`${this.url}${resource.id}/products`, resource);
      }
    
      getPublicaciones(params?: any, responseType?: any): Observable<any> {
        if (!params) return this.http.get(this.url);
        params = this.serialize(params);
        return this.http.get(`${this.url}${params.tienda_id}/products`, {
          params: this.object2Params(params),
          responseType: responseType
        });
      }
    
      getOrdenes(params?: any, responseType?: any): Observable<any> {
        if (!params) return this.http.get(this.url);
        params = this.serialize(params);
        return this.http.get(`${this.url}${params.tienda_id}/orders`, {
          params: this.object2Params(params),
          responseType: responseType
        });
      }
    
      sincronizarOrden(resource?: any): Observable<any> {
        resource = this.serialize(resource);
        return this.http.post(`${this.url}${resource.shop_id}/orders/${resource.order_id}`, resource);
      }

      excell(params?: any, responseType?: any): Observable<any> {
        if (!params) return this.http.get(this.url);
        params = this.serialize(params);
        return this.http.get(`${this.url}${params.id}/excell`, {
          params: this.object2Params(params),
          responseType: responseType
        });
      }
}
