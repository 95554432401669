import {Injectable} from '@angular/core';
import {NotificationsService} from "../api/notifications.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PreguntasMlModalComponent} from "../../modals/preguntas-ml-modal/preguntas-ml-modal.component";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    /**
     * Filters for getting notifications list
     * @type {{limit: number; offset: number}}
     */
    filters = {
        limit: 10,
        offset: 0
    };

    /**
     * Whenever notifications ar loading or not
     */
    loadingNotifications: boolean

    /**
     * Quantity of not read notifications
     * @type {number}
     */
    public notReadNotifications = 0;

    /**
     * array used for showing notifications where needed
     * @type {any[]}
     */
    public notifications = [];

    /**
     * Whether user wants to receive notifications or not
     */
    notificationsGranted: boolean;

    constructor(private notificationsService: NotificationsService, private router: Router,
                private ngbModal: NgbModal) {
    }

    /**
     * Method for handling the notification received via WebSocket
     * @param msg
     */
    handleNotification(msg) {
        if (this.notificationsGranted) {
            switch (msg.event) {
                case "order.new":
                    this.sendMessage(`Se ha creado una nueva orden con id: ${msg.orderId}`, msg);
                    break;
                case "order.cancelled":
                    this.sendMessage(`Se a cancelado una orden con id: ${msg.orderId}`, msg);
                    break;
                case "ml.question.new":
                    this.sendMessage(`Tiene una pregunta nueva con id: ${msg.mlQuestionId}`, msg);
            }
        }
    }

    /**
     * handles behavior when user clicks notification
     * @param notification
     */
    handleNotificationClick(notification, fromPushNotification: boolean) {
        switch (notification.event) {
            case "order.new" || "order.cancelled":
                this.router.navigate([`/ordenes/${notification.orderId}`]);
                break;
            case "ml.question.new":
                this.handleQuestionNotification(notification);
                break;

        }
        if (!notification.read)
            this.readNotification(notification, fromPushNotification);
    }

    /**
     * Method for sending push notification to user
     * @param message
     */
    sendMessage(message, notif) {
        let audio = new Audio("../../../../assets/sounds/notification.mp3");
        audio.play().then(() => {
            let notification = new Notification(message, {icon: "../assets/img/logo/klogin.png",});
            let self = this;
            notification.onclick = function (event) {
                event.preventDefault();
                self.handleNotificationClick(notif, true);
            }
        });
    }

    /**
     * Method for loading notifications from page zero
     */
    loadNotifications() {
        if (!this.loadingNotifications) {
            this.filters.offset = 0;
            this.notifications = [];
            this.getNotifications();
        }

    }

    /**
     * Method for loading notifications from next page
     */
    loadMoreNotifications() {
        if (this.loadingNotifications) {
            this.filters.offset++;
            this.getNotifications();
        }
    }

    /**
     * Method for obtaining notifications from API
     */
    getNotifications() {
        this.loadingNotifications = true;
        this.notificationsService.list(this.filters).subscribe(notifications => {
            this.loadingNotifications = false;
            this.notReadNotifications = notifications.data.paging.total - notifications.data.paging.totalRead;
            this.notifications.push(...notifications.data.results);
            this.setMessageToNotifications();
        }, error => {
            this.loadingNotifications = false;
        });
    }

    /**
     * Grants or deny permission to show notifications
     * @param {boolean} isGranted
     */
    setPermission(isGranted: boolean) {
        this.notificationsGranted = isGranted;
    }

    /**
     * set messages to notifications depending on the event
     */
    setMessageToNotifications() {
        this.notifications.forEach(notification => {
            switch (notification.event) {
                case "order.new":
                    notification.message = `Se ha creado una orden nueva con id ${notification.orderId}`
                    break;
                case "order.cancelled":
                    notification.message = `Se ha cancelado una orden con id ${notification.orderId}`
                    break;
                case "ml.question.new":
                    notification.message = `Tiene una pregunta nueva con id ${notification.ml_question_id}`
                    break;
            }
        });
    }

    /**
     * Change notification read from 0 to 1
     * @param notification
     */
    readNotification(notification, fromPushNotification: boolean) {
        this.notificationsService.read(notification.id).subscribe(() => {
            notification.read = 1;
            if (!fromPushNotification)
                this.notReadNotifications--;
        });
    }

    /**
     * change all notifications read to 1
     */
    readAllNotifications() {
        this.notificationsService.readAll().subscribe(() => {
            this.notReadNotifications = 0;
            this.notifications.forEach(notification => {
                notification.read = 1;
            }, error => {
            });
        })
    }

    handleQuestionNotification(notification) {
        const modalRef = this.ngbModal.open(PreguntasMlModalComponent);
        modalRef.componentInstance.id = notification.ml_question_id;
    }

}
