import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {EntityType} from "@app/shared/enums/entity-type.enum";

@Injectable()
export class PrecioVentaGeneralService extends ApiService{

  entityType = EntityType.PRECIO_VENTA;
  url = 'api/seteados/precioventa/';

  create(resource) {
    return this.http.post(`${this.url}general`, resource);
  }

}
