import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class MediasService extends ApiService {
  entityType = EntityType.MEDIA;
  url = 'api/medias/';

  uploadImage(resource: any): Observable<any> {
    return this.http.post(this.url + 'upload', resource);
  }

  importImagen(resource: any): Observable<any> {
    return this.http.post(this.url + 'uploadMasivo', resource);
  }

  getSkumasterImages(resource: any): Observable<any> {
    return this.http.get(`${this.url}skumaster`, { params: this.object2Params(resource) });
  }
}
