import { Injectable } from "@angular/core";
import { EstadisticasService } from "./shared/services/api/estadisticas.service";
import { AuthService } from "./shared/services/auth/auth.service";

@Injectable()
export class AppService {
  title: string;
  message: string;
  loadingMessage = "Cargando";
  nuevasNotificaciones: boolean = false;
  categoriaSeleccionada: any;
  procesosEjecutandose: string[] = [];
  storagedItems: any[] = [];
  estadisticas: any;

  constructor(
    protected estadisticasService: EstadisticasService,
    protected auth: AuthService
  ) {}

  getEstadisticas() {
    this.estadisticas = null;
    this.estadisticasService.list().subscribe(
      estadisticas => {
        this.estadisticas = estadisticas.data;
      },
      error => {}
    );
  }

  storageItem(item: string, value: any) {
    this.storagedItems[item] = value;
  }

  removeItem(item: string) {
    if (this.storagedItems[item]) this.storagedItems[item] = null;
  }

  getItem(item: string): any {
    return this.storagedItems[item];
  }
}
