import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '../../../_services/script-loader.service';

import WS from '../../../../assets/js/gos_web_socket_client.js';
import { NotificationService } from './notification.service';
import { environment } from  '@environments/environment';

//online
const CHAT_URL = environment.CHAT_URL;

export interface Message {
  message: any[];
}

@Injectable()
export class ChatService {
  constructor(private notificationService: NotificationService) {
    let webSocket = WS.connect(CHAT_URL);
    let self = this;
    //session is an Autobahn JS WAMP session.
    webSocket.on('socket/connect', function(session) {


      session.subscribe('notifications/channel', function(uri, payload) {
        self.notificationService.handleNotification(payload);
      });
    });

    //error provides us with some insight into the disconnection: error.reason and error.code
    webSocket.on('socket/disconnect', function(error) {

    });
  }
}
