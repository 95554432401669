import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { StorageService } from "../storage/storage.service";

@Injectable()
export class HttpService {
  constructor(
    @Inject("API_URL") private api_url: string,
    public storageService: StorageService,
    protected http: HttpClient
  ) {}
  /**
   * Shorthand method to make a GET request to the API using the given url.
   * Usually used to read data from the API
   *
   * @param url The url to request.
   * @param options The options of the request.
   * @returns {Observable<Response>} The response observable.
   */
  get(url: string, options?): Observable<any> {
    return this.http.get(this.api_url + url, options);
  }

  getWithoutAuthHeader(url: string, options?): Observable<any> {
    return this.http.get(this.api_url + url, options);
  }

  /**
   * Shorthand method to make a POST request to the API using the given url.
   * Usually used to send new data to the API.
   *
   * @param url The url to request.
   * @param body The request's body.
   * @param options The options of the request.
   * @returns {Observable<Response>} The response observable.
   */
  post(url: string, body: any, options?): Observable<any> {
    return this.http.post(this.api_url + url, body, options);
  }

  /**
   * Shorthand method to make a PUT request to the API using the given url.
   * Usually used to change data in the API.
   *
   * @param url The url to request.
   * @param body The request's body.
   * @param options The options of the request.
   * @returns {Observable<any>} The response observable.
   */
  put(url: string, body: any, options?): Observable<any> {
    return this.http.put(this.api_url + url, body, options);
  }

  /**
   * Shorthand method to make a DELETE request to the Api using the given url.
   * Used to delete data from the API.
   *
   * @param url The url to request.
   * @param options The options of the request.
   * @returns {Observable<Response>} The response observable.
   */
  delete(url: string, options?): Observable<any> {
    return this.http.delete(this.api_url + url, options);
  }
}
