import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { ApiService } from './api.service';

@Injectable()
export class PreguntasMlService extends ApiService {
  public plataformaId = 1;
  entityType = EntityType.PREGUNTA_ML;
  url = `api/mercadolibre/${this.plataformaId}/questions/`;

  responderPregunta(id, resource) {
    return this.http.post(`${this.url}/${id}/responder`, resource);
  }
}
