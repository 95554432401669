import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class LogisticasService extends ApiService {
  entityType = EntityType.LOGISTICA;
  url = 'api/logistica/';

  getProductos(id): Observable<any> {
    return this.http.get(`${this.url}${id}/productos`);
  }

  agregarProducto(id, resource) {
    resource = this.serialize(resource);
    return this.http.post(`${this.url}${id}/articulos`, resource);
  }

  eliminarProducto(data) {
    return this.http.delete(`${this.url}${data.id}/item/${data.productoVentaId}`);
  }
}
