import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ArrayOfNumbersPipe } from "./array_of_numbers.pipe";
import { CustomDatePipe } from "./custom-date.pipe";
import { EncodeUrlPipe } from "./encode-url.pipe";
import { RoundPipe } from "./round.pipe";
import { SnakeCase2TitlePipe } from "./snakecase-2-title.pipe";
import { TextDropPipe } from "./text-drop.pipe";

@NgModule({
  imports: [FormsModule, CommonModule],
  exports: [
    TextDropPipe,
    RoundPipe,
    EncodeUrlPipe,
    ArrayOfNumbersPipe,
    SnakeCase2TitlePipe,
    CustomDatePipe
  ],
  declarations: [
    TextDropPipe,
    RoundPipe,
    EncodeUrlPipe,
    ArrayOfNumbersPipe,
    SnakeCase2TitlePipe,
    CustomDatePipe
  ]
})
export class CustomPipesModule {}
