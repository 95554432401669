import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ErrorsError4Component } from './errors-error-4.component';
import { LayoutModule } from '@app/theme/layouts/layout.module';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: '',
    component: ErrorsError4Component
  }
];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), LayoutModule, NgbButtonsModule],
  exports: [RouterModule],
  declarations: [ErrorsError4Component]
})
export class ErrorsError4Module {}
