import { Injectable } from '@angular/core';
import { EntityType } from '@app/shared/enums/entity-type.enum';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';

@Injectable()
export class BodegasService extends ApiService {
    entityType = EntityType.BODEGA;
    url = 'api/bodega/';

    getStock(params?: any): Observable<any> {
        params = this.serialize(params);
        return this.http.get(`${this.url}stock-temporal`, { params: this.object2Params(params) });
    }

    agregarStock(resource: any): Observable<any> {
        return this.http.post(`${this.url}move-local`, this.serialize(resource));
    }

}
